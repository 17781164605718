import { ActivityIndicator, Section, Tag } from '@troon/ui';
import { For, Show, Suspense } from 'solid-js';
import { gql, TotalStatus } from '../graphql';
import { createFragment } from '../graphql/create-fragment';
import type { FragmentType } from '../graphql';
import type { JSX } from 'solid-js';

type Props = {
	fallbackContent?: JSX.Element;
	receipt: FragmentType<typeof PaymentInfoFragment> | undefined;
	upsell?: JSX.Element;
};

export function PaymentInfo(props: Props) {
	const receipt = createFragment(PaymentInfoFragment, props, 'receipt');
	return (
		<Suspense fallback={<ActivityIndicator class="text-brand">Calculating…</ActivityIndicator>}>
			<div>
				<table class="w-full">
					<thead class="sr-only">
						<tr>
							<th>Item</th>
							<th class="text-end font-semibold">Amount</th>
						</tr>
					</thead>
					<tbody class="border-b border-neutral-500">
						<For each={receipt?.items}>
							{(item) => (
								<tr>
									<td class="py-2">{item.label}</td>
									<td class="py-2 text-end font-semibold">{item.amount.displayValue}</td>
								</tr>
							)}
						</For>
						<Show when={props.upsell}>
							{(upsell) => (
								<tr>
									<td colspan={2} class="pb-2">
										{upsell()}
									</td>
								</tr>
							)}
						</Show>
					</tbody>
					<Show when={receipt?.total}>
						{(total) => (
							<tfoot>
								<tr class="text-lg">
									<td class="py-4 font-semibold">{statusToString[receipt!.totalStatus]}</td>
									<td class="py-4 text-end font-semibold">{total().displayValue}</td>
								</tr>
								<tr>
									<td rowSpan={2} class="text-sm text-neutral-700">
										* All amounts displayed are subject to additional taxes and fees, which may be added at check in.
									</td>
								</tr>
							</tfoot>
						)}
					</Show>
				</table>
				<Show when={receipt?.reimbursement}>
					{(reimbursement) => (
						<Section appearance="contained" class="gap-2 md:gap-2 md:p-4">
							<p class="flex justify-between">
								<span class="font-medium text-green-600">Reimbursed at check in</span>
								<Tag appearance="positive" rounded>
									{reimbursement().displayValue}
								</Tag>
							</p>
							<p class="text-sm">{receipt?.reimbursementDisclaimer}</p>
						</Section>
					)}
				</Show>
			</div>
		</Suspense>
	);
}

const statusToString: Record<TotalStatus, string> = {
	[TotalStatus.DueLater]: 'Total Due at Check-in',
	[TotalStatus.DueNow]: 'Total Due Now',
	[TotalStatus.Paid]: 'Total Paid',
};

const PaymentInfoFragment = gql(`fragment PaymentInfo on Receipt {
  total { displayValue }
  totalStatus
  items {
    label
    amount { displayValue }
    itemType
  }
  reimbursement { displayValue }
  reimbursementDisclaimer
}`);
